import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { apiUlrValue, apiUrl } from 'app/shared/utils';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class HttpCalIInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    const token = localStorage.token;
    if (!token) {
      const req1 = req.clone({
        url: apiUrl(req.url),
      });
      return next.handle(req1);
    }
    let req1 = null;
    if (req.url.indexOf(apiUlrValue()) >= 0) {
      req1 = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
        url: req.url,
      });
      return next.handle(req1);
    } else {
      req1 = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
        url: apiUrl(req.url),
      });
    }

    // return next.handle(req1);
    return next.handle(req1).pipe(
      catchError(err => {
        console.log(err, 'err');
        if (err instanceof HttpErrorResponse) {
          switch ((err as HttpErrorResponse).status) {
            case 422:
              return throwError(err.error);
            case 403:
              return throwError(err);
            case 401:
              if (err && err.error && err.error.message === 'Unauthorized') {
                return this.handle401Error(req1, next);
              } else {
                return throwError(err);
              }
            case 400:
              return throwError(err.error.message[0]);
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.authService.logout();
    // return this.router.navigate(['/login/shipping']);
    return this.authService.ssoLogin({});
  }
}
