import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppStorage } from '../cookies/app-storage';
import { apiUrl } from 'app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  reset_token: string;

  dataStore: {
    token: string;
    user: any;
  };
  private _token: BehaviorSubject<string>;
  private _user: BehaviorSubject<any>;
  constructor(
    @Inject(AppStorage) private appStorage: Storage,
    private readonly httpService: HttpClient,
    private readonly router: Router
  ) {
    this.dataStore = {
      token: null,
      user: null,
    };

    this._token = new BehaviorSubject(this.dataStore.token);
    this._user = new BehaviorSubject(this.dataStore.user);
    this.init();
  }
  public get $token(): Observable<any> {
    return this._token.asObservable();
  }

  public get token(): any {
    return this.dataStore.token;
  }

  public set token(value: any) {
    this.dataStore.token = value;
    this.appStorage.setItem('token', this.dataStore.token);
    this._token.next(this.dataStore.token);
  }

  public get user(): any {
    return this.dataStore.user;
  }
  public set user(value: any) {
    this.dataStore.user = value;
    this.appStorage.setItem('user', JSON.stringify(this.dataStore.user));
    this._user.next(this.dataStore.user);
  }

  public get $user(): Observable<any> {
    return this._user.asObservable();
  }

  init() {
    this.token = this.appStorage.getItem('token');
    try {
      this.user = JSON.parse(this.appStorage.getItem('user'));
    } catch (error) {
      this.user = null;
    }

    if (this.token) {
      this.isLoggedIn = true;
    }
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    return localStorage.getItem('userData');
  }

  loggedIn() {
    const token: string = localStorage.getItem('token');
    return token != null;
  }

  setPassword(request: any): any {
    return this.httpService.post('auth/reset-password', request).pipe(
      map((resp: any) => {
        this.token = resp.data.token;
        this.user = resp.data.user;
        this.isLoggedIn = true;
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('userData', JSON.stringify(resp.data));
        return resp;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }
  ssoLogin(request: any) {
    return this.httpService.post('auth/sso/login', request).pipe(
      map((resp: any) => {
        window.location.href = resp.data;
        return resp;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }

  login(request: any) {
    return this.httpService.post('auth/login', request).pipe(
      map((resp: any) => {
        if (resp.data.user.role.name === 'learner') {
          const err = new Error('Invalid Credentials');
          throw err;
        }
        this.token = resp.data.token;
        this.user = resp.data.user;
        this.isLoggedIn = true;
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('userData', JSON.stringify(resp.data));
        return resp;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }

  logout(request?: any) {
    if (request) {
      return this.httpService.get('auth/reset-log');
    }
    this.appStorage.clear();
    this.user = this.getUser();
    if (this.user) {
      const loggedinuser = JSON.parse(this.user);
      const roleName = loggedinuser.user.role.name;
      this.user = null;
      // return this.httpService.get('auth/sso/logout-callback');
      window.location.href = `https://identity-uat.app.north-standard.com/Account/Logout`;
      // if (roleName === 'content_admin') {
      //   localStorage.clear();
      //   this.router.navigate(['/login']);
      // } else {
      //   localStorage.clear();
      //   // this.router.navigate(['/login/shipping']);
      // }
      // window.location.href = apiUrl('auth/sso/login');
    }
  }

  logoutAngular() {
    this.appStorage.clear();
    this.isLoggedIn = false;
    this.token = null;
    this.user = null;

    return true;
  }

  forgotPassword(request: any): any {
    return this.httpService.post<any>(`auth/forgot-password`, request).pipe(
      map(response => {
        if (response.status === 200) {
          const data = response.message;
          return data;
        } else {
          this.token = null;
          this.user = null;
          return response;
        }
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }

  refreshToken() {
    const token: string = this.token;
    return this.httpService
      .post<any>(`auth/refresh-token`, { token: token })
      .pipe(
        map(response => {
          if (response.status == 200) {
            const data = response.data;
            this.token = data.token;
            this.user = data.user;
            this.isLoggedIn = true;
            return response;
          } else {
            this.isLoggedIn = false;
            this.token = null;
            this.user = null;
            return response;
          }
        }),
        catchError(error => {
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      );
  }

  getTosContent() {
    return this.httpService.get('auth/tos').pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }

  async updateTosContent(request: any) {
    try {
      const data = await this.httpService.put('auth/tos', request).pipe(
        map((resp: any) => {
          return resp.data;
        }),
        catchError((error) => {
          return throwError(JSON.parse(JSON.stringify(error)));
        })
      )
        .toPromise();
      return data;
    } catch (error_2) {
      throw error_2;
    }
  }

  getSSOUser(request: any) {
    return this.httpService.get(`auth/sso/get-user?code=${request}`).pipe(
      map((resp: any) => {
        if (resp.data.user.role.name === 'learner') {
          const err = new Error('Invalid Credentials');
          throw err;
        }
        this.token = resp.data.token;
        this.user = resp.data.user;
        this.isLoggedIn = true;
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('userData', JSON.stringify(resp.data));
        return resp;
      }),
      catchError(error => {
        return throwError(JSON.parse(JSON.stringify(error)));
      })
    );
  }
}
