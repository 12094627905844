import { Injectable } from '@angular/core';
import { throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as _ from "underscore";
import * as moment from "moment";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    onFilterChanged: Subject<any>;
    filterBy: string = 'Admin';

    constructor(
        private readonly httpService: HttpClient,
    ) {
        this.onFilterChanged = new Subject();
    }

    historyList(request: any) {
        return this.httpService.get<any>('user/history', { params: request }).pipe(
            map((resp: any) => {
                return resp.data;
            }),
            catchError((error) => {
                return throwError(JSON.parse(JSON.stringify(error)));
            })
        )
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                error = ((error.graphQLErrors && error.graphQLErrors[0]) ? error.graphQLErrors[0] : error);
                throw error;
            })

    }

    getUsers(request: any) {
        return this.httpService.get<any>('user', { params: request }).pipe(
            map((resp: any) => {
                return resp.data;
            }),
            catchError((error) => {
                return throwError(JSON.parse(JSON.stringify(error)));
            })
        )
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                error = ((error.graphQLErrors && error.graphQLErrors[0]) ? error.graphQLErrors[0] : error);
                throw error;
            })

    }
    getUser(id: any) {
        return this.httpService.get<any>(`user/${id}`).pipe(
            map((resp: any) => {
                return resp.data;
            }),
            catchError((error) => {
                return throwError(JSON.parse(JSON.stringify(error)));
            })
        )
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                error = ((error.graphQLErrors && error.graphQLErrors[0]) ? error.graphQLErrors[0] : error);
                throw error;
            })
    }

    deleteUser(id: string) {
        return this.httpService.delete(`user/delete/${id}`).pipe(
            map((resp: any) => {
                return resp.data;
            }),
            catchError((error) => {
                return throwError(JSON.parse(JSON.stringify(error)));
            })
        )
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                throw error;
            })
    }

    updateProfile(request: any) {
        return this.httpService.post('user/update-admin-profile', request).pipe(
            map((resp: any) => {
                return resp.data;
            }),
            catchError((error) => {
                return throwError(JSON.parse(JSON.stringify(error)));
            })
        )
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                throw error;
            })
    }

    updateUser(request: any) {
        if (!request.id) {
            request.isVerified = true;
            request.type = 'admin-register';
        }
        return this.httpService.post('user/update-user', request).pipe(
            map((resp: any) => {
                return resp.data;
            }),
            catchError((error) => {
                return throwError(JSON.parse(JSON.stringify(error)));
            })
        )
            .toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                throw error;
            })
    }

    changePassword(request: any) {
        return this.httpService.post('auth/change-password', request).pipe(
            map((resp: any) => resp.message),
            catchError((error) => {
                return throwError(error);
            })
        ).toPromise()
            .then((data: any) => {
                return data;
            }).catch((error) => {
                throw error;
            })
    }

    updateSplashDialogFlag() {
        return this.httpService
            .post('user/update-splash-dialog-flag', {})
            .pipe(
                map((resp: any) => {
                    return resp.data;
                }),
                catchError(error => {
                    return throwError(JSON.parse(JSON.stringify(error)));
                })
            )
            .toPromise()
            .then((data: any) => {
                return data;
            })
            .catch(error => {
                throw error;
            });
    }
}
